.category__featured {
  width: 100%;
  height: 70vh;
  overflow: hidden;
  background: rgb(34,193,195);
  background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem;
  align-items: flex-start; 
}

.category__featured h1 {
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 2rem 5rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
}

.category__featured a {
  display: block;
  color: #000;
  padding: 1rem 2.5rem;
  background: #fff;
  font-weight: bold;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.8);
  margin-top: 1rem;
  border-radius: 0.3rem;
}

.category__featured a:hover {
  background: rgba(70, 60, 105, 1);
  color: #fff;
  font-weight: bold;
  box-shadow: 5px 5px rgb(47, 40, 73);
}

.description {
  padding-top: 2rem;
}

.description h1, .description h2, .description h3, .description h4, .description h5, .description h6 {
  margin-bottom: 1.5rem;
}

.images {
  /* width: 100%; */
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem; */
  padding: 2rem 3rem;
  /* margin-top: 2rem; */
  /* background: rgba(0, 0, 0, 0.1); */
}

.images img {
  /* width: 300px; */
  height: 350px;
  object-fit: cover;
  /* box-shadow: 10px 5px #d8d8d8; */
  border-radius: 0.5rem;
}

.category__content {
  /* padding: 2rem 0; */
  padding-bottom: 2rem;
  word-wrap: break-word;
}

/* .category__content img {
  max-width: 450px;
  margin: 1.5rem 1rem;
} */

.category__content p {
  line-height: 1.8;
  /* margin: 0; */
  margin-bottom: 0.5rem;
}

.category__content p > br, .description p > br {
  display: none;
}

/* .category__content p:has(+ img) {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: chartreuse;
  padding: 10px;
  align-items: center;
} */

.category__content img {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  box-shadow: 5px 5px #d8d8d8;
  border-radius: 0.5rem;
  margin: 10px 0;
}

.category__content ul, .category__content ol {
  list-style-position: inside;
}

@media only screen and (max-width:768px) {
  .category__featured {
    padding: 0.5rem;
  }

  .category__content img {
    width: 100%;
    object-fit: cover;
    margin: 0.5rem;
    /* max-width: 450px; */
    /* margin: 1.5rem 1rem; */
  }

  .category__content ul, .category__content ol {
    margin-left: 0.9rem;
  }
}