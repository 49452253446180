.subscription__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  /* position: relative; */
}

.subscription__item {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
  position: relative;
  margin: auto 0;
}

.subscription__item.active {
  /* background-color: rgb(109, 211, 109); */
  border: 2px solid rgb(109, 211, 109);
  color: rgb(109, 211, 109);
  padding: 1.5rem 1rem;
}

.active_icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color:rgb(109, 211, 109);
  font-size: 1.2rem;
  font-weight: bold;
}

.subscription__item:hover {
  border: 2px solid rgb(109, 211, 109);
  /* color: #fff; */
}

.subscription__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscription__heading h4 {
  margin: 0;
}

.subscription__name {
  text-transform: capitalize;
}

.subscription__item ul {
  list-style: none;
}

.premium_privileges {
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  width: 50%;
  /* padding: 1rem; */
}

.premium_heading {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.premium_heading h4 {
  margin: 0;
}

.premium_privileges p {
  /* margin: 0; */
  color: #28a745;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.premium_privileges svg {
  margin-right: 1rem;
  color: #28a745;
  font-size: 1.3rem;
  font-weight: 500;
}

.card_container {
  display: grid;
 justify-content: center;
  width: 100%;
  /* height: 100%; */
}

.card_form {
  width: 400px;
  padding: 1.5rem;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 10px;
}

@media screen and (max-width: 750px) {
  .subscription__items {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  .subscription__item {
    padding: .7rem;
  }

  .subscription__heading {
    flex-direction: column;
  }

  .subscription__heading h4 {
    margin-bottom: 5px;
  }

  .premium_privileges {
    width: 100%;
  }

  .card_form {
    width: 100%;
   
  }
}