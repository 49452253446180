.popular_categories {
  padding: 5rem 0 6rem;
}
.categories {
  display: flex;
}

.popular_categories a {
  color: #545454;
}

/* .categories img {
  width: 350px;
} */

.tabPanel {
  background: linear-gradient(110deg, #ffffff 0%, #f1f7ff 100%);
  /* border: 2px solid #CED4DA; */
  border-top: none;
  border-radius: 1rem;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
  color: #6c757d;
  margin-top: 30px;
}

.tabPanel-content {
  padding: 1.5rem 0.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-items: center;
}

.tabPanel p {
  padding: 10px 0;
  color: #6c757d;
}

@media screen and (max-width: 768px) {
  .tabPanel-content {
    grid-template-columns: 1fr;
    max-height: 310px;
    overflow-x: hidden;
  }
}
