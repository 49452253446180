:root {
  --primaryColor: #124870;
  --secondaryColor: #0fb2e8;
}

/* .App {
  overflow-x: hidden; 
  width: 100vw;
} */

a:hover {
  color: var(--secondaryColor);
}

/* .container {
  width: 1000px;
  margin: 0 auto;
  padding: 60px 0;
  border-top: 1px solid #000;
} */

a.cta-link {
  color: #fff;
  padding: 5px;

  border-radius: 10px;
}

a.cta-link:hover {
  color: #fff;
  background-color: #448424;
  /* border-bottom: 1px solid red; */
}

.announcement {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  flex-direction: row-reverse;
  text-align: left;
  align-items: center;
  padding: 0.5rem 1rem;
  /* background-color: #0FB2E8; */
  background: linear-gradient(110deg, #f1f7ff 0%, #0fb2e8 100%);
  letter-spacing: 1px;
}

.section-header {
  font-family: Overpass;
  text-align: center;
  font-size: xx-large;
  margin-bottom: 40px;
}

.active-link {
  font-weight: bold;
  color: #fff;
  background-color: #124870;
  /* border-left: 3px solid #124870; */
}

.active-link:hover {
  color: white;
}

.capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: bold;
}

/* .btn {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 25px;
}

.btn-primary {
  padding: 10px 25px;
  background-color: rgb(17, 71, 112);
  color: #fff;
  border: none;
  border-radius: 6px;
  outline: none;
   width: 100%; 
   margin: 10px 0; 
  display: block;
   width: 100%; 
  cursor: pointer;
}

.btn-secondary {
  border: 1px solid #04bfb3;
  border-radius: 20px;
  background: transparent
}

.btn-secondary:hover {
  background-color: var(--secondaryColor);
  color: #fff;
} */

.activeList {
  background: #047bfe !important;
  color: #fff;
}

button:disabled {
  background: gray;
}

/* .nice-dates-navigation, .nice-dates-day {
  color: #111;
} */
.nice-dates-popover {
  max-width: 300px !important;
}

input {
  padding: 10px 15px;
  border: 2px solid #e9ecef;
  border-radius: 3px;
}

input:focus {
  border: 2px solid #94b4c2;
  /* background-color: #fff; */
}

.social-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
}

.facebook-login,
.google-login {
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  outline: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 100%;
}

.facebook-login {
  background-color: #3b5998;
  color: #fff;
}

/* .facebook-login span{
  margin-left: 1.5rem;
} */

.facebook-login svg,
.google-login svg {
  font-size: 1.3rem;
  margin-right: 1.5rem;
}

.google-login {
  /* margin-top: 1.3rem; */
  background-color: #e9ecef;
}

/* .google-login span {
  margin-left: 1.5rem;
} */
.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
}

.divider:before,
.divider:after {
  content: " ";
  width: 120px;
  height: 2px;
  /* background-color: #9FE738; */
  background-color: #0169d9;
  /* color: #9FE738; */
  color: #0169d9;
  display: inline-block;
  margin: 0 10px;
}

.error {
  color: red;
}

.modal-backdrop {
  z-index: 90 !important;
}

.modal {
  z-index: 100 !important;
}

.profile-stat-chart {
  align-self: center;
}

.profile-stat {
  border-left: 2px solid #ccc;
}

.profile-user-avatar {
  position: absolute;
  /* top: 28%;
    left: 35%; */
  font-size: 3rem;
  color: #00e29f;
  align-self: center;
  /* justify-content: center; */
  transform: translateX(5px);
}

.feature {
  width: 100%;
  height: 65vh;
  color: white;
  text-align: center;
}

#about-feature {
  background: url("./assets/img/bg2.jpeg") center center no-repeat;
  background-size: cover;
}

#privacy-feature {
  background: url("https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80")
    center center no-repeat;
  background-size: cover;
}

#contact-feature {
  background: url("./assets/img/contact.jpeg") center center no-repeat;
  background-size: cover;
}

.grid-center {
  display: grid;
  place-content: center;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.about-section {
  padding: 1rem 0;
}

.about-section p {
  line-height: 1.5rem;
}

/** Scroll bar style */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(180, 179, 179);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*******/

@media screen and (max-width: 750px) {
  .social-container {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }

  .profile-stat-chart {
    margin-bottom: 1rem;
  }

  .profile-stat {
    border-left: 0px solid transparent;
    border-top: 2px solid #ccc;
    padding-top: 1rem;
  }

  .profile-user-avatar {
    top: 30%;
    left: 37%;
  }
}

.pie-chart {
  height: auto;
}

@media screen and (min-width: 1500px) {
  .profile-user-avatar {
    font-size: 5rem;
  }
}
