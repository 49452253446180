.home-hero {
  width: 100%;
  height: 70vh;
  /* background: url('../../assets/img/home-hero-bg.jpeg') center center no-repeat; */
  /* background: url('../../assets/img/Corporate-Training-Workshops.jpg') center center no-repeat; */
  background: url("../../assets/img/bg3.webp") center center no-repeat;
  background-size: cover;

  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* .videoContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  text-align: center;
  overflow-x: hidden;
  display: flex;
  justify-content:center;
  align-items: center;
} */

/* video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.featured-image {
  width: 50%;
  height: 100%;
  
  margin: 0;
  padding: 0;
  background: url('../../assets/img/woodworker.jpeg') center bottom no-repeat;
  background-size: cover;
 
}

.slanted-div {
  width: 50%;
  height: 100%;
  position: relative;
  z-index: 1;
  background: #fff;
}

.slanted-div:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  bottom: 0;
  transform-origin: right top;
  transform: skewX(10deg);
} */

/* .featured-image:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  bottom: 0;
  transform-origin: left top;
  transform: skewX(-5deg);
} */
/* .home-hero::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0,0,0,0.4);
} */

.home-hero > div {
  margin: auto;
  /* background-color: #fff; */
  /* position: absolute;
  top: 50%;
  left: 30%; */
}

.home-hero h2 {
  color: #fff;
  font-weight: bold;
}

.home-form-container {
  width: 100%;
  /* padding-top: 1rem; */
  background: rgb(14 13 13 / 60%);
  /* background: #124870; */
  border-radius: 10px;
  /* border-top-right-radius: 10px;
  border-top-left-radius: 10px; */
  margin: 0 auto;
  transform: translateY(50px);
  /* position: absolute;
  top:0;
  left: 0;
  width: 60%;
  height: 100%; */
  /* top:0;
  left: 0;
  width: 100%;
  height: 100%;  */
  /* clip-path: polygon(0 0, 32% 0, 56% 100%, 0 100%); */
}

#form-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.get-quote-form {
  display: flex;
  justify-content: space-between;
}

.get-quote-form input {
  padding: 10px 15px;
  border-radius: 3px;
  /* background-color: #E9ECEF; */
  border: 2px solid #e9ecef;
  outline: none;
  width: 100%;
  /* margin-right: 15px; */
  /* flex: 2; */
}

.get-quote-form input:focus {
  border: 2px solid #2fb2e8;
  /* background-color: #fff; */
}

.get-quote-form button {
  padding: 7.5px 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  flex: 1;
  color: #fff;
  background-color: #1c67a0;
  /* background-color: #1992ee; */
}

.full-getquote-form {
  width: 100%;
  position: fixed;
  top: 0;
}

@media screen and (max-width: 768px) {
  .home-hero {
    background-size: cover;
  }

  .home-form-container {
    top: 30px;
    border-radius: 10px;
  }

  /* .get-quote-form input {
    margin-bottom: .5rem !important;
  } */
}
