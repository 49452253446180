@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, 'Poppins', BlinkMacSystemFont, 'Arial', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width: 100vw; */
  /* background-color: #F8F9FA; */
}



a:hover {
  text-decoration: none !important;
}

/* Stripe element styles*/

label.stripe {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  display: block;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  /* max-width: 500px; */
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::-webkit-input-placeholder {
  color: #aab7c4;
}

input:-ms-input-placeholder {
  color: #aab7c4;
}

input::placeholder {
  color: #aab7c4;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
:root {
  --primaryColor: #124870;
  --secondaryColor: #0fb2e8;
}

/* .App {
  overflow-x: hidden; 
  width: 100vw;
} */

a:hover {
  color: #0fb2e8;
  color: var(--secondaryColor);
}

/* .container {
  width: 1000px;
  margin: 0 auto;
  padding: 60px 0;
  border-top: 1px solid #000;
} */

a.cta-link {
  color: #fff;
  padding: 5px;

  border-radius: 10px;
}

a.cta-link:hover {
  color: #fff;
  background-color: #448424;
  /* border-bottom: 1px solid red; */
}

.announcement {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  flex-direction: row-reverse;
  text-align: left;
  align-items: center;
  padding: 0.5rem 1rem;
  /* background-color: #0FB2E8; */
  background: linear-gradient(110deg, #f1f7ff 0%, #0fb2e8 100%);
  letter-spacing: 1px;
}

.section-header {
  font-family: Overpass;
  text-align: center;
  font-size: xx-large;
  margin-bottom: 40px;
}

.active-link {
  font-weight: bold;
  color: #fff;
  background-color: #124870;
  /* border-left: 3px solid #124870; */
}

.active-link:hover {
  color: white;
}

.capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: bold;
}

/* .btn {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 25px;
}

.btn-primary {
  padding: 10px 25px;
  background-color: rgb(17, 71, 112);
  color: #fff;
  border: none;
  border-radius: 6px;
  outline: none;
   width: 100%; 
   margin: 10px 0; 
  display: block;
   width: 100%; 
  cursor: pointer;
}

.btn-secondary {
  border: 1px solid #04bfb3;
  border-radius: 20px;
  background: transparent
}

.btn-secondary:hover {
  background-color: var(--secondaryColor);
  color: #fff;
} */

.activeList {
  background: #047bfe !important;
  color: #fff;
}

button:disabled {
  background: gray;
}

/* .nice-dates-navigation, .nice-dates-day {
  color: #111;
} */
.nice-dates-popover {
  max-width: 300px !important;
}

input {
  padding: 10px 15px;
  border: 2px solid #e9ecef;
  border-radius: 3px;
}

input:focus {
  border: 2px solid #94b4c2;
  /* background-color: #fff; */
}

.social-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
}

.facebook-login,
.google-login {
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  outline: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 100%;
}

.facebook-login {
  background-color: #3b5998;
  color: #fff;
}

/* .facebook-login span{
  margin-left: 1.5rem;
} */

.facebook-login svg,
.google-login svg {
  font-size: 1.3rem;
  margin-right: 1.5rem;
}

.google-login {
  /* margin-top: 1.3rem; */
  background-color: #e9ecef;
}

/* .google-login span {
  margin-left: 1.5rem;
} */
.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
}

.divider:before,
.divider:after {
  content: " ";
  width: 120px;
  height: 2px;
  /* background-color: #9FE738; */
  background-color: #0169d9;
  /* color: #9FE738; */
  color: #0169d9;
  display: inline-block;
  margin: 0 10px;
}

.error {
  color: red;
}

.modal-backdrop {
  z-index: 90 !important;
}

.modal {
  z-index: 100 !important;
}

.profile-stat-chart {
  align-self: center;
}

.profile-stat {
  border-left: 2px solid #ccc;
}

.profile-user-avatar {
  position: absolute;
  /* top: 28%;
    left: 35%; */
  font-size: 3rem;
  color: #00e29f;
  align-self: center;
  /* justify-content: center; */
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.feature {
  width: 100%;
  height: 65vh;
  color: white;
  text-align: center;
}

#about-feature {
  background: url(/static/media/bg2.f4805cbc.jpeg) center center no-repeat;
  background-size: cover;
}

#privacy-feature {
  background: url("https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80")
    center center no-repeat;
  background-size: cover;
}

#contact-feature {
  background: url(/static/media/contact.5b496d96.jpeg) center center no-repeat;
  background-size: cover;
}

.grid-center {
  display: grid;
  place-content: center;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.about-section {
  padding: 1rem 0;
}

.about-section p {
  line-height: 1.5rem;
}

/** Scroll bar style */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(180, 179, 179);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*******/

@media screen and (max-width: 750px) {
  .social-container {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }

  .profile-stat-chart {
    margin-bottom: 1rem;
  }

  .profile-stat {
    border-left: 0px solid transparent;
    border-top: 2px solid #ccc;
    padding-top: 1rem;
  }

  .profile-user-avatar {
    top: 30%;
    left: 37%;
  }
}

.pie-chart {
  height: auto;
}

@media screen and (min-width: 1500px) {
  .profile-user-avatar {
    font-size: 5rem;
  }
}

.home-hero {
  width: 100%;
  height: 70vh;
  /* background: url('../../assets/img/home-hero-bg.jpeg') center center no-repeat; */
  /* background: url('../../assets/img/Corporate-Training-Workshops.jpg') center center no-repeat; */
  background: url(/static/media/bg3.2ed758c0.webp) center center no-repeat;
  background-size: cover;

  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* .videoContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  text-align: center;
  overflow-x: hidden;
  display: flex;
  justify-content:center;
  align-items: center;
} */

/* video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.featured-image {
  width: 50%;
  height: 100%;
  
  margin: 0;
  padding: 0;
  background: url('../../assets/img/woodworker.jpeg') center bottom no-repeat;
  background-size: cover;
 
}

.slanted-div {
  width: 50%;
  height: 100%;
  position: relative;
  z-index: 1;
  background: #fff;
}

.slanted-div:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  bottom: 0;
  transform-origin: right top;
  transform: skewX(10deg);
} */

/* .featured-image:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  bottom: 0;
  transform-origin: left top;
  transform: skewX(-5deg);
} */
/* .home-hero::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0,0,0,0.4);
} */

.home-hero > div {
  margin: auto;
  /* background-color: #fff; */
  /* position: absolute;
  top: 50%;
  left: 30%; */
}

.home-hero h2 {
  color: #fff;
  font-weight: bold;
}

.home-form-container {
  width: 100%;
  /* padding-top: 1rem; */
  background: rgb(14 13 13 / 60%);
  /* background: #124870; */
  border-radius: 10px;
  /* border-top-right-radius: 10px;
  border-top-left-radius: 10px; */
  margin: 0 auto;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  /* position: absolute;
  top:0;
  left: 0;
  width: 60%;
  height: 100%; */
  /* top:0;
  left: 0;
  width: 100%;
  height: 100%;  */
  /* clip-path: polygon(0 0, 32% 0, 56% 100%, 0 100%); */
}

#form-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.get-quote-form {
  display: flex;
  justify-content: space-between;
}

.get-quote-form input {
  padding: 10px 15px;
  border-radius: 3px;
  /* background-color: #E9ECEF; */
  border: 2px solid #e9ecef;
  outline: none;
  width: 100%;
  /* margin-right: 15px; */
  /* flex: 2; */
}

.get-quote-form input:focus {
  border: 2px solid #2fb2e8;
  /* background-color: #fff; */
}

.get-quote-form button {
  padding: 7.5px 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  flex: 1 1;
  color: #fff;
  background-color: #1c67a0;
  /* background-color: #1992ee; */
}

.full-getquote-form {
  width: 100%;
  position: fixed;
  top: 0;
}

@media screen and (max-width: 768px) {
  .home-hero {
    background-size: cover;
  }

  .home-form-container {
    top: 30px;
    border-radius: 10px;
  }

  /* .get-quote-form input {
    margin-bottom: .5rem !important;
  } */
}

.search-bar-container {
  /* width: 460px; */
  /* margin: 0 auto; */
  /* height: 52px; */
  width: 100%;
  position: relative;
}

/* .search-bar {
  background: transparent;
  border: 1px solid #f6f6f6;
  font-size: 15px;
  padding-left: 40px;
  color: #4e5860;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  padding: 10px 15px;
}

.search-bar:focus {
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
} */

.search-bar-icon {
  position: absolute;
  left: 12px;
  top: 16px;
  font-size: 22px;
  color: #ac0a0a;
}

.line-height-1 {
  line-height: 1;
}

/* .search-result {
  position: absolute;
  top: 52px;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.28);
  z-index: 500;
} */

/* .list-group {
  height: 250px;
} */

.list-group-item {
  cursor: pointer;
}

.list-group-item.active,
.list-group-item:hover {
  background-color: #e1e3e5;
  border-color: #d3d5d7;
}

.visible {
  display:block
}

.invisible {
  display:none;
}

.how-we-do {
  padding: 3rem 1rem 6rem;
}

/* .how-we-do .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  top: -145px;
  padding: 0;
  margin-bottom: -100px;
} */

.howWeDo-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  /* top: -145px; */
  /* padding: 3rem; */
  /* margin-bottom: -100px; */
}

.we-do-card {
  /* border: 1px solid gray; */
  max-width: 330px;
  /* height: 320px; */
  box-shadow: 0px 24px 64px rgba(235, 234, 234, 0.3);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 16px;
  justify-content: space-evenly;
  background: #fff;
  color: #212529;
}

.lift-up {
  position: relative;
  top: -50px;
}

.we-do-description {
  padding: 10px 10px 0px;
}

.how-we-do p {
  /* margin-top: 15px; */
  line-height: 1.5;
}

.how-we-do h2 {
  font-size: 1.5rem;
}

/* .btn {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.btn button {
  background-color: #114470;
  padding: 1rem 2rem;
  margin: 30px 0 0;
} */

@media screen and (max-width: 1205px) {
  .how-we-do {
    padding: 3rem;
    margin: 0.5rem 0;
  }
  .howWeDo-wrapper {
    justify-content: center;
  }

  .lift-up {
    top: 0px;
  }

  .we-do-card {
    max-width: 280px;
    height: auto;
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .how-we-do {
    padding: 3rem;
    margin: 0.5rem 0;
  }
  .howWeDo-wrapper {
    justify-content: center;
  }

  .lift-up {
    top: 0px;
  }

  .we-do-card {
    margin: 10px 0;
  }
}

section.recent-activity {
  padding: 7rem;
  width: 100%;
}

.activity-container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 3rem 2rem 1rem;
  margin: 0 1rem;
  /* width: 100%; */
}

.fluid {
  background-image: url(/static/media/bg4.0e9beaa9.webp);
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.3);
}

.activity h2 {
  text-align: center;
  margin-bottom: 50px;
  font-family: "Overpass";
  font-size: xx-large;
}

.activity__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 1rem;
  border-bottom: 1px solid #dee2e6;
}

.activity__item:hover {
  background: #eee;
}

@media only screen and (max-width: 750px) {
  section.recent-activity {
    padding: 3.5rem 2.5rem;
  }
}

.list-business {
  margin-top: 0;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  position: relative;
}

.parallax_background {
  height: 500px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: url(/static/media/listBusiness.06b7f46a.webp) center center no-repeat;
  background-size: cover;
}

.list-business__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
}

.list-business__content h3 {
  font-size: 1.5rem;
}

.list-business button {
  margin-top: 60px;
  background-color: #1c67a0;
}

.list-business a {
  text-decoration: none;
  color: #fff;
}

@media screen and (max-width: 800px) {
  .list-business h2 {
    font-size: 1.5rem;
  }
}

.popular_categories {
  padding: 5rem 0 6rem;
}
.categories {
  display: flex;
}

.popular_categories a {
  color: #545454;
}

/* .categories img {
  width: 350px;
} */

.tabPanel {
  background: linear-gradient(110deg, #ffffff 0%, #f1f7ff 100%);
  /* border: 2px solid #CED4DA; */
  border-top: none;
  border-radius: 1rem;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
  color: #6c757d;
  margin-top: 30px;
}

.tabPanel-content {
  padding: 1.5rem 0.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-items: center;
}

.tabPanel p {
  padding: 10px 0;
  color: #6c757d;
}

@media screen and (max-width: 768px) {
  .tabPanel-content {
    grid-template-columns: 1fr;
    max-height: 310px;
    overflow-x: hidden;
  }
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.Testmonials_testimonial__Dgf6M{
    
    width: 100%;
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #080808;
    background: linear-gradient(110deg, #ccc 0%, #f1f7ff 100%);

}

.Testmonials_box__3r2sY{
    position: relative;
    width: 1300px;
    min-width: 350px;
    min-height: 250px;
    padding: 40px 0;
    overflow: auto;
}

.Testmonials_wrapper__JdCJQ{
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    transition: 1s;
}

.Testmonials_cardT__1GGS_{
    width: 800px;
    min-height: 100%;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0 10px;
}

.Testmonials_cardThumb__1pDvS{
    width: 70%;
    height: 250px;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: relative;

}

.Testmonials_clientImg__cFf4T{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Testmonials_clientName__1AWj1{
    position: absolute;
    bottom: 10px;
    right: 10px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 5px 20px; 
    background: #fff;
    text-transform: capitalize;
    font-size: 14px;

}

.Testmonials_cardBody__TTLDl{
    width: 120%;
    min-height: 100%;
    height: auto;
    margin-left: 20px;
    position: relative;
    padding-bottom: 50px;
}

.Testmonials_review__ehRFH{
    font-size: 20px;
    line-height: 30px;
    margin-top: 30px;
}

.Testmonials_indicators__1SFSk{
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.Testmonials_indicators__1SFSk button{
    background: none;
    border: none;
    outline: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
    cursor: pointer;
    margin-left: 5px;
    transition: .5s;

}

button.Testmonials_active__igLO0{
    width: 20px;
    border-radius: 50px;
    background: #fff;
}

@media(max-width: 800px){
    .Testmonials_box__3r2sY{
        width: 50%;
        margin: auto;
    }
    .Testmonials_cardT__1GGS_{
        flex-direction: column;
    }
    .Testmonials_cardThumb__1pDvS{
        width: 100%;
        border-radius: 10px;
    }
    .Testmonials_cardBody__TTLDl{
        width: 100%;
        min-height: auto;
        margin: 0;
    }
}
nav.home {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 2;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px;
}

.nav-custom {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: wraps;
  position: static;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  padding: 20px 30px;
  justify-content: space-around;
  align-items: center;
  height: 13vh;
}
@media screen and (min-width: 1800px) {
  .nav-custom {
    height: 11vh;
  }
}

/* .logo {
  flex: 20%; 
} */

.logo {
  justify-self: flex-start;
}

img.logo {
  padding: 0.3rem;
  max-width: 240px;
}

.logo a {
  margin: 0;
  padding: 0;
}

/* .nav-menu {
  flex: 65%;
} */

.nav-custom ul {
  display: flex;
}

.nav-custom ul li {
  list-style: none;
}

.nav-custom a {
  text-decoration: none;
  color: #114470;
  padding: 10px 9px;
  padding-bottom: 15px;
  border: none;

  /* margin: 0 5px; */
}

/* nav li {
  padding: 10px;
} */

.nav-custom li a:hover {
  /* background: rgb(228, 207, 207); */

  /* transition: all 0.2s ease-in-out; */
  /* border-bottom: 3px solid red; */
  background-color: whitesmoke;
  color: #1f6593;
  /* color: #000; */
}

.loginSignup {
  flex-shrink: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: flex-end;
  align-items: center;
  grid-gap: 10px;
}

.nav-dropdown img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.hamburger-menu {
  color: #114470;
  font-size: 2rem;
  display: none;
  cursor: pointer;
  margin: 1rem;
}

/* .logo {
  
} */

/* nav div > button {
  margin-right: 10px;
} */

/** media screens **/
@media screen and (max-width: 1310px) {
  .nav-menu {
    display: none;
  }
  .loginSignup {
    display: none;
  }
  .hamburger-menu {
    display: block;
  }
  .logo img {
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 650px) {
  .nav-menu {
    display: none;
  }
  .loginSignup {
    display: none;
  }
  .hamburger-menu {
    display: block;
  }

  .nav-custom {
    padding: 10px;
  }
  .logo img {
    max-width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 2000px) {
  img.logo {
    max-width: 280px;
    height: auto;
  }
}

.About_row1__1AzzU {
  background-color: #fff;
  max-width: 100%;
}
.About_row2__1syR_ {
  background-color: #f1f7ff;
  max-width: 100%;
}

.About_mt__1Jc8k {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.About_aboutSection1__3poq7 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 144px 30px 144px 30px;
}

.About_aboutSection2__2jjpZ {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 144px 30px 144px 30px;
}

.About_img1__395SG {
  object-fit: cover;
  padding: 0 2rem 0 0;
  max-width: 100%;
  height: auto;
}

.About_img2__1lg4- {
  object-fit: cover;
  padding: 0 0 0 2rem;
  max-width: 100%;
  height: auto;
}

.About_description1__3Vq8u {
  width: 100%;
  padding: 0 20px 0 10px;
}
.About_description2__2MwgS {
  width: 90%;
  padding: 0 10px 0 20px;
}

@media (max-width: 1124px) {
  .About_row1__1AzzU {
    width: 100%;
    margin: auto;
  }
  .About_row2__1syR_ {
    width: 100%;
    margin: auto;
  }
  .About_mt__1Jc8k {
    flex-direction: column;
  }
  .About_aboutSection1__3poq7 {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .About_aboutSection2__2jjpZ {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .About_description1__3Vq8u {
    width: 70%;
    padding: 50px 0 70px 0;
  }
  .About_description2__2MwgS {
    width: 70%;
    padding: 70px 0 50px 0;
  }

  .About_abImg__39yua {
    width: 70%;
  }

  .About_img1__395SG {
    width: 100%;
    min-height: auto;
    margin: 0;
    padding: 70px 0 0 0;
  }
  .About_img2__1lg4- {
    width: 100%;
    min-height: auto;
    margin: 0;
    padding: 0 0 70px 0;
  }
}



.ContactUs_row1__3oYzw {
    height: 85vh;
    padding: 6rem 2.5rem;
    
    
}

.ContactUs_col1__2-GJt div{
    padding: 0rem 2.5rem 0;
    
}

.ContactUs_row2__3UcvT {
    height: 70vh;
    background-color: #F1F7FF;
    padding: 6rem 2.5rem;
   
}

.ContactUs_row2__3UcvT ul{

    padding: 2rem;
   
}

.ContactUs_col2__mqXHa div{
    padding: 2.5rem;    
}

.footer {
  /* margin-top: 50px; */
  background-color: #0a273f;
  color: #f1f2f0;
  font-size: 10px;
  width: 100%;
  padding: 20px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding-bottom: 10px;
}

.footer-content ul li {
  list-style-type: none;
}

.footer-content a {
  display: block;
  text-decoration: none;
  color: #f1f2f0;
  padding-top: 10px;
  padding: 0 0 10px 0;
  font-size: 16px;
}

.footer-content a:hover {
  color: #114870;
}

.social {
  border-bottom: 1px solid darkgrey;
}

.copy-right {
  /* display: flex;
  justify-content: space-between; */
  text-align: center;
  padding: 10px 0;
}

.copy-right a {
  color: #363636;
  padding-left: 10px;
  text-decoration: none;
}

.copy-right p {
  padding: 10px;
  text-decoration: none;
}

@media screen and (max-width: 479px) {
  .footer-content {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin: 0;
  }

  .home-form-container {
    top: 30px;
    border-radius: 10px;
  }

  /* .get-quote-form input {
    margin-bottom: .5rem !important;
  } */
}

.SocialFollow_socialContainer__2G0W6 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px;
}

.SocialFollow_social__2X98r {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 35px;
  width: 35px;
  font-family: "Titillium Web", sans-serif;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  color: #1da1f2;
  background: #f1f2f0;
  margin: 5px;
  transition: 1s;
  padding: 0 7px 0 0;
}

.SocialFollow_social__2X98r span {
  width: 0px;
  overflow: hidden;
  transition: 1s;
  text-align: center;
  font-size: 12px;
  color: #1da1f2;
}

.SocialFollow_social__2X98r:hover {
  width: 135px;
  border-radius: 5px;
}

.SocialFollow_social__2X98r a {
  color: #1da1f2;
}

.SocialFollow_social__2X98r:hover span {
  padding: 2px;
  width: -webkit-max-content;
  width: max-content;
}

a.SocialFollow_facebook__14qE0 {
  color: #1778f2;
}

a.SocialFollow_twitter__FGxdg {
  color: #1da1f2;
}

.subscription__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  /* position: relative; */
}

.subscription__item {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
  position: relative;
  margin: auto 0;
}

.subscription__item.active {
  /* background-color: rgb(109, 211, 109); */
  border: 2px solid rgb(109, 211, 109);
  color: rgb(109, 211, 109);
  padding: 1.5rem 1rem;
}

.active_icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color:rgb(109, 211, 109);
  font-size: 1.2rem;
  font-weight: bold;
}

.subscription__item:hover {
  border: 2px solid rgb(109, 211, 109);
  /* color: #fff; */
}

.subscription__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscription__heading h4 {
  margin: 0;
}

.subscription__name {
  text-transform: capitalize;
}

.subscription__item ul {
  list-style: none;
}

.premium_privileges {
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  width: 50%;
  /* padding: 1rem; */
}

.premium_heading {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.premium_heading h4 {
  margin: 0;
}

.premium_privileges p {
  /* margin: 0; */
  color: #28a745;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.premium_privileges svg {
  margin-right: 1rem;
  color: #28a745;
  font-size: 1.3rem;
  font-weight: 500;
}

.card_container {
  display: grid;
 justify-content: center;
  width: 100%;
  /* height: 100%; */
}

.card_form {
  width: 400px;
  padding: 1.5rem;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 10px;
}

@media screen and (max-width: 750px) {
  .subscription__items {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  .subscription__item {
    padding: .7rem;
  }

  .subscription__heading {
    flex-direction: column;
  }

  .subscription__heading h4 {
    margin-bottom: 5px;
  }

  .premium_privileges {
    width: 100%;
  }

  .card_form {
    width: 100%;
   
  }
}
.forgot-password-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  padding-top: 11rem;
}

.forgot-password-box {
  width: 50%;
  border-radius: 5px;
  padding: 1rem;
  justify-self: center;
  align-items: center;
  margin-top: 5rem;
}

@media screen and (max-width: 760px) {
  .forgot-password-box {
    width: 100%;
  }
}

.category__featured {
  width: 100%;
  height: 70vh;
  overflow: hidden;
  background: rgb(34,193,195);
  background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem;
  align-items: flex-start; 
}

.category__featured h1 {
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 2rem 5rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
}

.category__featured a {
  display: block;
  color: #000;
  padding: 1rem 2.5rem;
  background: #fff;
  font-weight: bold;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.8);
  margin-top: 1rem;
  border-radius: 0.3rem;
}

.category__featured a:hover {
  background: rgba(70, 60, 105, 1);
  color: #fff;
  font-weight: bold;
  box-shadow: 5px 5px rgb(47, 40, 73);
}

.description {
  padding-top: 2rem;
}

.description h1, .description h2, .description h3, .description h4, .description h5, .description h6 {
  margin-bottom: 1.5rem;
}

.images {
  /* width: 100%; */
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem; */
  padding: 2rem 3rem;
  /* margin-top: 2rem; */
  /* background: rgba(0, 0, 0, 0.1); */
}

.images img {
  /* width: 300px; */
  height: 350px;
  object-fit: cover;
  /* box-shadow: 10px 5px #d8d8d8; */
  border-radius: 0.5rem;
}

.category__content {
  /* padding: 2rem 0; */
  padding-bottom: 2rem;
  word-wrap: break-word;
}

/* .category__content img {
  max-width: 450px;
  margin: 1.5rem 1rem;
} */

.category__content p {
  line-height: 1.8;
  /* margin: 0; */
  margin-bottom: 0.5rem;
}

.category__content p > br, .description p > br {
  display: none;
}

/* .category__content p:has(+ img) {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: chartreuse;
  padding: 10px;
  align-items: center;
} */

.category__content img {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  box-shadow: 5px 5px #d8d8d8;
  border-radius: 0.5rem;
  margin: 10px 0;
}

.category__content ul, .category__content ol {
  list-style-position: inside;
}

@media only screen and (max-width:768px) {
  .category__featured {
    padding: 0.5rem;
  }

  .category__content img {
    width: 100%;
    object-fit: cover;
    margin: 0.5rem;
    /* max-width: 450px; */
    /* margin: 1.5rem 1rem; */
  }

  .category__content ul, .category__content ol {
    margin-left: 0.9rem;
  }
}
