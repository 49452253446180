.row1 {
  background-color: #fff;
  max-width: 100%;
}
.row2 {
  background-color: #f1f7ff;
  max-width: 100%;
}

.mt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.aboutSection1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 144px 30px 144px 30px;
}

.aboutSection2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 144px 30px 144px 30px;
}

.img1 {
  object-fit: cover;
  padding: 0 2rem 0 0;
  max-width: 100%;
  height: auto;
}

.img2 {
  object-fit: cover;
  padding: 0 0 0 2rem;
  max-width: 100%;
  height: auto;
}

.description1 {
  width: 100%;
  padding: 0 20px 0 10px;
}
.description2 {
  width: 90%;
  padding: 0 10px 0 20px;
}

@media (max-width: 1124px) {
  .row1 {
    width: 100%;
    margin: auto;
  }
  .row2 {
    width: 100%;
    margin: auto;
  }
  .mt {
    flex-direction: column;
  }
  .aboutSection1 {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .aboutSection2 {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .description1 {
    width: 70%;
    padding: 50px 0 70px 0;
  }
  .description2 {
    width: 70%;
    padding: 70px 0 50px 0;
  }

  .abImg {
    width: 70%;
  }

  .img1 {
    width: 100%;
    min-height: auto;
    margin: 0;
    padding: 70px 0 0 0;
  }
  .img2 {
    width: 100%;
    min-height: auto;
    margin: 0;
    padding: 0 0 70px 0;
  }
}
