.socialContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 35px;
  width: 35px;
  font-family: "Titillium Web", sans-serif;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  color: #1da1f2;
  background: #f1f2f0;
  margin: 5px;
  transition: 1s;
  padding: 0 7px 0 0;
}

.social span {
  width: 0px;
  overflow: hidden;
  transition: 1s;
  text-align: center;
  font-size: 12px;
  color: #1da1f2;
}

.social:hover {
  width: 135px;
  border-radius: 5px;
}

.social a {
  color: #1da1f2;
}

.social:hover span {
  padding: 2px;
  width: max-content;
}

a.facebook {
  color: #1778f2;
}

a.twitter {
  color: #1da1f2;
}
