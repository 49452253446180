nav.home {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 2;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px;
}

.nav-custom {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: wraps;
  position: static;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  padding: 20px 30px;
  justify-content: space-around;
  align-items: center;
  height: 13vh;
}
@media screen and (min-width: 1800px) {
  .nav-custom {
    height: 11vh;
  }
}

/* .logo {
  flex: 20%; 
} */

.logo {
  justify-self: flex-start;
}

img.logo {
  padding: 0.3rem;
  max-width: 240px;
}

.logo a {
  margin: 0;
  padding: 0;
}

/* .nav-menu {
  flex: 65%;
} */

.nav-custom ul {
  display: flex;
}

.nav-custom ul li {
  list-style: none;
}

.nav-custom a {
  text-decoration: none;
  color: #114470;
  padding: 10px 9px;
  padding-bottom: 15px;
  border: none;

  /* margin: 0 5px; */
}

/* nav li {
  padding: 10px;
} */

.nav-custom li a:hover {
  /* background: rgb(228, 207, 207); */

  /* transition: all 0.2s ease-in-out; */
  /* border-bottom: 3px solid red; */
  background-color: whitesmoke;
  color: #1f6593;
  /* color: #000; */
}

.loginSignup {
  flex-shrink: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: flex-end;
  align-items: center;
  grid-gap: 10px;
}

.nav-dropdown img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.hamburger-menu {
  color: #114470;
  font-size: 2rem;
  display: none;
  cursor: pointer;
  margin: 1rem;
}

/* .logo {
  
} */

/* nav div > button {
  margin-right: 10px;
} */

/** media screens **/
@media screen and (max-width: 1310px) {
  .nav-menu {
    display: none;
  }
  .loginSignup {
    display: none;
  }
  .hamburger-menu {
    display: block;
  }
  .logo img {
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 650px) {
  .nav-menu {
    display: none;
  }
  .loginSignup {
    display: none;
  }
  .hamburger-menu {
    display: block;
  }

  .nav-custom {
    padding: 10px;
  }
  .logo img {
    max-width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 2000px) {
  img.logo {
    max-width: 280px;
    height: auto;
  }
}
