.forgot-password-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  padding-top: 11rem;
}

.forgot-password-box {
  width: 50%;
  border-radius: 5px;
  padding: 1rem;
  justify-self: center;
  align-items: center;
  margin-top: 5rem;
}

@media screen and (max-width: 760px) {
  .forgot-password-box {
    width: 100%;
  }
}
