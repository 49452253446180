.footer {
  /* margin-top: 50px; */
  background-color: #0a273f;
  color: #f1f2f0;
  font-size: 10px;
  width: 100%;
  padding: 20px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding-bottom: 10px;
}

.footer-content ul li {
  list-style-type: none;
}

.footer-content a {
  display: block;
  text-decoration: none;
  color: #f1f2f0;
  padding-top: 10px;
  padding: 0 0 10px 0;
  font-size: 16px;
}

.footer-content a:hover {
  color: #114870;
}

.social {
  border-bottom: 1px solid darkgrey;
}

.copy-right {
  /* display: flex;
  justify-content: space-between; */
  text-align: center;
  padding: 10px 0;
}

.copy-right a {
  color: #363636;
  padding-left: 10px;
  text-decoration: none;
}

.copy-right p {
  padding: 10px;
  text-decoration: none;
}

@media screen and (max-width: 479px) {
  .footer-content {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin: 0;
  }

  .home-form-container {
    top: 30px;
    border-radius: 10px;
  }

  /* .get-quote-form input {
    margin-bottom: .5rem !important;
  } */
}
