

.row1 {
    height: 85vh;
    padding: 6rem 2.5rem;
    
    
}

.col1 div{
    padding: 0rem 2.5rem 0;
    
}

.row2 {
    height: 70vh;
    background-color: #F1F7FF;
    padding: 6rem 2.5rem;
   
}

.row2 ul{

    padding: 2rem;
   
}

.col2 div{
    padding: 2.5rem;    
}