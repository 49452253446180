*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.testimonial{
    
    width: 100%;
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #080808;
    background: linear-gradient(110deg, #ccc 0%, #f1f7ff 100%);

}

.box{
    position: relative;
    width: 1300px;
    min-width: 350px;
    min-height: 250px;
    padding: 40px 0;
    overflow: auto;
}

.wrapper{
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    transition: 1s;
}

.cardT{
    width: 800px;
    min-height: 100%;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0 10px;
}

.cardThumb{
    width: 70%;
    height: 250px;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: relative;

}

.clientImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.clientName{
    position: absolute;
    bottom: 10px;
    right: 10px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 5px 20px; 
    background: #fff;
    text-transform: capitalize;
    font-size: 14px;

}

.cardBody{
    width: 120%;
    min-height: 100%;
    height: auto;
    margin-left: 20px;
    position: relative;
    padding-bottom: 50px;
}

.review{
    font-size: 20px;
    line-height: 30px;
    margin-top: 30px;
}

.indicators{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.indicators button{
    background: none;
    border: none;
    outline: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #fff;
    cursor: pointer;
    margin-left: 5px;
    transition: .5s;

}

button.active{
    width: 20px;
    border-radius: 50px;
    background: #fff;
}

@media(max-width: 800px){
    .box{
        width: 50%;
        margin: auto;
    }
    .cardT{
        flex-direction: column;
    }
    .cardThumb{
        width: 100%;
        border-radius: 10px;
    }
    .cardBody{
        width: 100%;
        min-height: auto;
        margin: 0;
    }
}