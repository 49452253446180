.how-we-do {
  padding: 3rem 1rem 6rem;
}

/* .how-we-do .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  top: -145px;
  padding: 0;
  margin-bottom: -100px;
} */

.howWeDo-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  /* top: -145px; */
  /* padding: 3rem; */
  /* margin-bottom: -100px; */
}

.we-do-card {
  /* border: 1px solid gray; */
  max-width: 330px;
  /* height: 320px; */
  box-shadow: 0px 24px 64px rgba(235, 234, 234, 0.3);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 16px;
  justify-content: space-evenly;
  background: #fff;
  color: #212529;
}

.lift-up {
  position: relative;
  top: -50px;
}

.we-do-description {
  padding: 10px 10px 0px;
}

.how-we-do p {
  /* margin-top: 15px; */
  line-height: 1.5;
}

.how-we-do h2 {
  font-size: 1.5rem;
}

/* .btn {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.btn button {
  background-color: #114470;
  padding: 1rem 2rem;
  margin: 30px 0 0;
} */

@media screen and (max-width: 1205px) {
  .how-we-do {
    padding: 3rem;
    margin: 0.5rem 0;
  }
  .howWeDo-wrapper {
    justify-content: center;
  }

  .lift-up {
    top: 0px;
  }

  .we-do-card {
    max-width: 280px;
    height: auto;
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .how-we-do {
    padding: 3rem;
    margin: 0.5rem 0;
  }
  .howWeDo-wrapper {
    justify-content: center;
  }

  .lift-up {
    top: 0px;
  }

  .we-do-card {
    margin: 10px 0;
  }
}
