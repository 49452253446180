section.recent-activity {
  padding: 7rem;
  width: 100%;
}

.activity-container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 3rem 2rem 1rem;
  margin: 0 1rem;
  /* width: 100%; */
}

.fluid {
  background-image: url(../../assets/img/bg4.webp);
  background-size: cover;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.3);
}

.activity h2 {
  text-align: center;
  margin-bottom: 50px;
  font-family: "Overpass";
  font-size: xx-large;
}

.activity__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 1rem;
  border-bottom: 1px solid #dee2e6;
}

.activity__item:hover {
  background: #eee;
}

@media only screen and (max-width: 750px) {
  section.recent-activity {
    padding: 3.5rem 2.5rem;
  }
}
