.search-bar-container {
  /* width: 460px; */
  /* margin: 0 auto; */
  /* height: 52px; */
  width: 100%;
  position: relative;
}

/* .search-bar {
  background: transparent;
  border: 1px solid #f6f6f6;
  font-size: 15px;
  padding-left: 40px;
  color: #4e5860;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  padding: 10px 15px;
}

.search-bar:focus {
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
} */

.search-bar-icon {
  position: absolute;
  left: 12px;
  top: 16px;
  font-size: 22px;
  color: #ac0a0a;
}

.line-height-1 {
  line-height: 1;
}

/* .search-result {
  position: absolute;
  top: 52px;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.28);
  z-index: 500;
} */

/* .list-group {
  height: 250px;
} */

.list-group-item {
  cursor: pointer;
}

.list-group-item.active,
.list-group-item:hover {
  background-color: #e1e3e5;
  border-color: #d3d5d7;
}

.visible {
  display:block
}

.invisible {
  display:none;
}
