.list-business {
  margin-top: 0;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  position: relative;
}

.parallax_background {
  height: 500px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: url("../../assets/img/listBusiness.webp") center center no-repeat;
  background-size: cover;
}

.list-business__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
}

.list-business__content h3 {
  font-size: 1.5rem;
}

.list-business button {
  margin-top: 60px;
  background-color: #1c67a0;
}

.list-business a {
  text-decoration: none;
  color: #fff;
}

@media screen and (max-width: 800px) {
  .list-business h2 {
    font-size: 1.5rem;
  }
}
